import { createRouter, createWebHistory } from 'vue-router';
import Session from 'supertokens-web-js/recipe/session';
import getUser from '@/services/user-info.js'
import backendRequest from '@/services/backendRequest.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeNew.vue'),
  },
  {
    path: '/archiver',
    name: 'Archiver',
    component: () => import('../views/Archiver.vue'),
  },
  {
    path: '/fleet',
    name: 'FleetManagement',
    component: () => import('../views/Fleet.vue'),
  },
  {
    path: '/maintenanceadmin',
    name: 'OperationHistory',
    component: () => import('../views/MaintenanceAdmin.vue'),
  },
  {
    path: '/dlap',
    name: 'DLAP',
    component: () => import('../views/dlap.vue'),
  },
  {
    path: '/auth/reset-password',
    name: 'PasswordReset',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/builds',
    name: 'builds',
    component: () => import('../views/BuildMonitor.vue'),
  },
  {
    path: '/alarmsearch',
    name: 'AlarmSearch',
    component: () => import('../views/AlarmSearch.vue'),
  },
  {
    path: '/alarms',
    name: 'Alarm Configuration',
    component: () => import('../views/Alarms.vue'),
  },
  {
    path: '/mtsmonitoring',
    name: 'MtsMonitoring',
    component: () => import('../views/MtsMonitoring.vue'),
  },
  {
    path: '/lclmonitoring',
    name: 'LclMonitoring',
    component: () => import('../views/LclMonitoring.vue'),
  },
  {
    path: '/datatrending',
    name: 'DataTrending',
    component: () => import('../views/DataTrending.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('../views/reset-password.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue'),
  },
  {
    path: '/pspdfkit',
    name: 'PSPFDKitView',
    component: () => import('../views/PSPDFKit.vue'),
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: () => import('../views/Sandbox.vue'),
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../views/Favorites.vue'),
  },
  {
    path: '/select',
    name: 'Select',
    component: () => import('../views/Select.vue'),
  },
  {
    path: '/machine/:id',
    name: 'Machine',
    component: () => import('../views/Machine.vue'),
    props: true,
  },
  {
    path: '/machineinfo/:id',
    name: 'MachineInfo',
    component: () => import('../views/MachineInfo.vue'),
    props: true,
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('../views/Messages.vue'),
  },
  {
    path: '/section/:id',
    name: 'Section',
    component: () => import('../views/Section.vue'),
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/Article.vue'),
    props: true,
  },
  {
    path: '/pdfview/:postid',
    name: 'PdfView',
    component: () => import('../views/PdfView.vue'),
    props: true,
  },
  {
    path: '/Content_edit/:id',
    name: 'Content_edit',
    component: () => import('../views/Content_edit.vue'),
    props: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/403.vue'),
    props: true,
  },
  {
    path: '/globalops',
    name: 'GlobalOps',
    component: () => import('../views/GlobalOps.vue'),
  },
  {
    path: '/operationsmonitoring',
    name: 'OperationsMonitoring',
    component: () => import('../views/OperationsMonitoring.vue'),
  },
  {
    path: '/maintenanceadmin',
    name: 'MaintenanceAdmin',
    component: () => import('../views/MaintenanceAdmin.vue'),
  },
  {
    path: '/usermanagement',
    name: 'UserManagement',
    component: () => import('../views/UserManagement.vue'),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});
router.beforeEach(async (to, from) => {
  //console.log("TO:",to);
  return Session.doesSessionExist().then(async value => {
    let checkUser = await getUser();
    if ((!value || checkUser === null) && to.name !== 'login' && to.name !== 'reset-password') {
      localStorage.removeItem("bOpus_user");
      return '/login';
    } else if (value && (to.name === 'login' || to.name === undefined)) {
      return '/';
    }
  });
})

export default router;
