import {createStore} from 'vuex';

export default createStore({
  state: {
    selectCollapse: false,
    bookCollapse: false,
    sectionCollapse: false,
    user: null,
    useremail: '',
    loggedin: false,
    isEditable: true,
    //startpage: '/',
    //startpageloaded: false,
    //startpagename: '',
    usertoken: '',
    authModalShow: false,
    count: 2,
    edit: false,
    menu: false,
    disabledDialog: false,
    currentlocation: '',
    book: {
      category: {
        name: 'No Category',
        color: '#bcbcbc',
      },
      title: '',
      subtitle: '',
      data: [],
    },
    isChanged: false,
    targetPath: '',
    treeSelection: [],
    customerSelection: '',
    sectionSelection: '',
    contentIndex: '',
    contentType: 'txt',
    path: {},
    fromContentEdit: false,
    ifLoading: false,
    ifSaved: false,
    ifError: false,
    breadcrumbPopup: false,
    verticalBreadcrumb: [],
    movePlace: false,
    radioButtonId: null,
    moveToSelect: {},
    modalToComeback: '',
    beforeLoad: false,
    iconsList: fetch('/icons-list.json').then(res => res.json()).then(async function (icons) { return icons }),
    operationsLocation: '',
    search: false,
  },
  mutations: {
    savingPage(state) {
      state.ifLoading = true;
      setTimeout(() => {
        state.ifLoading = false;
      }, 3000);
    },
    pageSaved(state) {
      state.ifLoading = false;
      state.ifSaved = true;
      setTimeout(() => {
        state.ifSaved = false;
      }, 1000);
    },
    savingError(state) {
      console.log('err');
      state.ifLoading = false;
      state.ifSaved = false;
      state.ifError = true;
      setTimeout(() => {
        state.ifError = false;
      }, 1000);
    },
    toggle(state) {
      // mutate state
      state.draggable = !state.draggable;
    },
    contentEditInfo(state, num) {
      state.contentIndex = num;
      state.contentType = state.book.data[num].name;
    },
    contentTypeEdit(state, type) {
      console.log(type,'type change')
      state.contentType = type;
    },
    changedPage(state) {
      state.isChanged = true;
      setTimeout(() => {
        state.isChanged = false;
      }, 3000);
    },
    // updateStartPageName(state, arr) {
    //   state.startpagename = arr;
    // },
    // updateStartPage(state, arr) {
    //   state.startpage = arr;
    // },
    ifAdmin(state) {
      state.isEditable = true;
    },
  },
  getters: {
    getBook(state) {
      const originalBook = state.book;
      const book = JSON.parse(JSON.stringify(originalBook));
      return book;
    },
    getUser(state) {
      return state.user;
    },
    getLocation(state) {
      return state.operationsLocation;
    },
  },
});
